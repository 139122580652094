// If you want to override variables do it here
@import "variables";

// Import styles
@import "@coreui/coreui/scss/coreui";
@import "@coreui/chartjs/scss/coreui-chartjs";

// Vendors
@import "vendors/simplebar";

// Custom styles for this theme
@import "theme";

// If you want to add custom CSS you can put it here
@import "custom";

.react-confirm-alert-overlay {
    z-index: 1111 !important;
}
body.react-confirm-alert-body-element {
    overflow: inherit !important;
}
