body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin-bottom: 0px !important;
}

* {
  white-space: "pre-wrap";
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Độ rộng của thanh cuộn */
  height: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #1b9e3e; /* Màu nền của thanh cuộn */
  border-radius: 5px; /* Đường viền cong của thanh cuộn */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Màu nền của vùng chứa thanh cuộn */
}
.header > .container-fluid {
  min-height: 20px !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.none-bg {
  background-color: transparent !important;
}

.modal-dialog-scrollable .modal-content {
  overflow: visible !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes zoom {
  0% {
    -webkit-transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.1, 1.1);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

/* Standard syntax */
@keyframes zoom {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

.auto-zoom {
  -webkit-animation: spin 1.5s infinite; /* Chrome, Safari, Opera */
  animation: spin 1.5s infinite;
}
